<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card>
      <Header :event="event" focused/>
      <v-card-title class="headline">
        Configure Activity Types
      </v-card-title>
      <v-card-text>   
        <p>Configure activity types for this event.</p>
      </v-card-text>   

      <div v-if="event && request">

      <v-data-table
        v-if="request.custom_types"
        :headers="headers"
        :items="request.custom_types"
        :items-per-page="100"
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mx-4">
            <v-toolbar-title class="ml-0 subtitle">Custom Activity Types</v-toolbar-title>
            <v-spacer/>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:item.track_mode="{ item }">
          {{ $helpers.displayText(siteData.activity_track_modes, item.track_mode) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn text @click="openDialog(item)">
            <v-icon small title="Edit" class="me-1">fa-pencil-alt</v-icon>
            Edit
          </v-btn>
          <v-icon small color="red" title="Delete" class="mr-4" @click="deleteItem(item)">
            fa-trash
          </v-icon>
        </template>
        <template v-slot:footer>
          <v-btn text @click="addRow"><v-icon small color="gray" class="mr-2">fa-plus-circle</v-icon> Add activity type</v-btn>
        </template>
      </v-data-table>        

        <v-card-text>   
          <v-btn class="mt-8 mb-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit">Save</v-btn>
          <v-alert v-if="error || !valid" class="mt-4" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>
        </v-card-text>   
      </div>      
    </v-card>


    <v-dialog v-if="editedItem" v-model="showDialog" max-width="650px" @input="v => v || closeDialog()">
      <v-card>
        <v-card-title class="headline">{{ editedItem.id ? 'Edit Activity Type' : 'Add Activity Type' }}</v-card-title>
        <v-form ref="editForm">
        <v-card-text>
          <v-text-field 
            v-model="editedItem.name" 
            label="Name of activity" 
            required 
            :rules="nameRules"
            />

          <v-select
            :items="siteData.activity_track_modes"
            v-model="editedItem.track_mode" 
            item-value="type"
            item-text="text"
            label="Track mode"
            :rules="selectValueRules"
            />

          <v-select
            :items="siteData.activities"
            v-model="editedItem.base_type" 
            item-value="type"
            :item-text="item => $helpers.getActivityTypeLabel(item.type)"
            label="Base Type (optional)"
            />

          <v-text-field 
            v-if="editedItem.track_mode != 'TIME_ONLY'"
            v-model="editedItem.value_label" 
            label="Data entry field label" 
            />

          <MarkdownEditor v-model="editedItem.description" :eventId="event.id" label="Instructions (optional)" description="Instructions for this activity type, displayed to participants."  />
          
          <DurationTextArea 
            v-if="editedItem.track_mode != 'VALUE_ONLY'"
            v-model="editedItem.goal_duration_in_s" 
            label="Goal duration (optional)" 
            hide-details 
            />
          
          <DurationTextArea 
            v-if="editedItem.track_mode != 'VALUE_ONLY'"
            v-model="editedItem.max_duration_in_s" 
            label="Max duration (optional)" 
            hide-details 
            />


        </v-card-text>
        </v-form>

        <v-card-actions>
          <v-btn color="secondary" text @click="closeDialog">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary"  :loading="$store.getters.isLoading" @click="saveDialog">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <SupportButton/>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import DurationTextArea from "@/components/DurationTextArea";
import EventImageUploadForm from "@/components/EventImageUploadForm";
import SupportButton from '@/components/SupportButton.vue'
import MarkdownEditor from '@/components/MarkdownEditor.vue'
import EventHeader from "@/components/EventHeader.vue";
import ToggleButtonInput from "@/components/ToggleButtonInput.vue";
import EventUtil from "@/util/eventUtil";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    DateWithTimeZonePicker,
    DateAndTimeWithTimeZonePicker,
    DistanceTextArea,
    DurationTextArea,
    EventImageUploadForm,
    ToggleButtonInput,
    SupportButton,
    MarkdownEditor,
    EventHeader,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
      error: null,
      event: null,
      request: null,
      showDialog: false,
      editedItem: null,
      editedIndex: -1,
      defaultItem: { name: null, description: null, base_type: null, goal_duration_in_s: null, max_duration_in_s: null },
      nameRules: [
        v => !!v || "Please enter the name",
      ],
      selectValueRules: [
        v => !!v || "Please select a value",
      ],
      headers: [
        { text: 'Name', value: 'name', sortable: false, class: 'ZZ_w-66p' },
        { text: 'Mode', value: 'track_mode', sortable: false},
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async submit() {
        this.error = null;
        try {
          var response = await eventManagerService.putActivityTypes(this.event.id, this.request);
          if (response.data.status === 'OK') {
            //this.$router.push({ name: 'eventmanagerView', params: { id: this.eventUtil.event.id }});
            this.$helpers.toastResponse(this, response.data, 'Saved successfully.');
            return true;
          }
          else {
            this.error = response.data.msg;
          }
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
    },

    async addRow() {
      //this.request.custom_types.push({});
      this.openDialog({});
    },

    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.request = {
        custom_types: this.event.custom_activity_types || [],
      };
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },


    async deleteItem(item) {
      if (confirm(`Are you sure you want to delete this activity type ${item.name}?`)) {
        this.request.custom_types = this.request.custom_types.filter(x => x !== item);
        await this.submit();
        await this.getProfile();
      }
    },

    openDialog(item) {
      this.editedIndex = this.request.custom_types.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showDialog = true;
    },
    closeDialog () {
      this.showDialog = false;
      this.editedIndex = -1;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      })
    },
    async saveDialog() {
      if (this.editedIndex > -1) {
        Object.assign(this.request.custom_types[this.editedIndex], this.editedItem);
      }
      else {
        this.request.custom_types.push(this.editedItem);
      }
        if (this.$refs.editForm.validate()) {
          var success = await this.submit();
          if (success) this.closeDialog();
        }
        else {
          console.log('Invalid form state', this.$refs.editForm.errorMessages);
        }
    },
  },
  computed: {
    valid() {
      return this.request 
          && this.request.custom_types
          && this.request.custom_types.length;
    },

    eventUtil() {
      return new EventUtil(this, this.event);
    },

    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Configure Activity Types', disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
</style>

